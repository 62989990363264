var _a;
import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { baseApi } from '../api/api';
import { adminApi } from '../api/apiAdmin';
import { profileApi } from '../api/profileApi';
import { storeApi } from '../api/storeApi';
import { tagApi } from '../api/tagApi';
import { authSlice } from '../common/authSlice';
import { rtkQueryErrorLogger } from '../middlewares/rtkQueryErrorLogger';
export var store = configureStore({
    reducer: (_a = {},
        _a[authSlice.name] = authSlice.reducer,
        _a[baseApi.reducerPath] = baseApi.reducer,
        _a[profileApi.reducerPath] = profileApi.reducer,
        _a[tagApi.reducerPath] = tagApi.reducer,
        _a[storeApi.reducerPath] = storeApi.reducer,
        _a),
    middleware: function (getDefaultMiddleware) {
        return getDefaultMiddleware()
            .concat(baseApi.middleware)
            .concat(profileApi.middleware)
            .concat(tagApi.middleware)
            .concat(storeApi.middleware)
            .concat(rtkQueryErrorLogger);
    }
});
export var reload = function () {
    store.dispatch(baseApi.util.invalidateTags([
        'Users',
        'User',
        'AccessKeys',
        'AccessKey',
        'Overview'
    ]));
    store.dispatch(adminApi.util.invalidateTags(['AdminUsers', 'AdminUser']));
    store.dispatch(tagApi.util.invalidateTags(['Tags', 'Entity', 'Entities']));
    store.dispatch(storeApi.util.invalidateTags(['Stores']));
};
export var useAppDispatch = function () { return useDispatch(); };
export var useAppSelector = useSelector;
