var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useAuth0 } from '@auth0/auth0-react';
import { TagSharp } from '@mui/icons-material';
import ApiIcon from '@mui/icons-material/Api';
import CableIcon from '@mui/icons-material/Cable';
import LogoutIcon from '@mui/icons-material/Logout';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import { Drawer, List } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useGetProfileQuery } from '../../api/profileApi';
import UserIcon from '../../common/assets/users/plain_user.svg';
import { getConfig } from '../../common/config';
import { useAuthentication } from '../../common/hooks/authentication';
import { notification } from '../../common/Notifications';
import { reload } from '../../store/store';
import { AppMenuItem } from '../atoms/AppMenuItem';
import { AvatarItem } from '../atoms/AvatarItem';
import Backdrop from '../molecules/Backdrop';
import { TenantSelector } from '../molecules/TenantSelector';
import { ModalView } from './ModalView';
export var AppMenu = function () {
    var _a, _b, _c, _d;
    var profile = useGetProfileQuery().data;
    var _e = useAuthentication(), switchTenant = _e.switchTenant, isPricerTenant = _e.isPricerTenant, isPricerEmployee = _e.isPricerEmployee;
    var logout = useAuth0().logout;
    var navigator = useNavigate();
    var _f = useState(false), open = _f[0], setOpen = _f[1];
    var _g = useState(false), isLoading = _g[0], setIsLoading = _g[1];
    var _h = useState(false), isTenantSelectorOpen = _h[0], setIsTenantSelectorOpen = _h[1];
    var isAdminSide = window.location.pathname === '/admin/users' && isPricerEmployee();
    var switchToTenant = function (tenantId) { return __awaiter(void 0, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    setIsTenantSelectorOpen(false);
                    setOpen(false);
                    setIsLoading(true);
                    return [4 /*yield*/, switchTenant(tenantId)];
                case 1:
                    _a.sent();
                    localStorage.setItem('currentTenant', tenantId);
                    reload();
                    navigator('/');
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    notification.error('Failed to switch tenant');
                    return [3 /*break*/, 3];
                case 3:
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(Drawer, { onMouseEnter: function () { return setOpen(true); }, onMouseLeave: function () { return setOpen(false); }, PaperProps: {
            sx: {
                background: "linear-gradient(to right, ".concat(isAdminSide ? '#E3BCBC' : '#F3F6F3', " 3.75rem, #FFFFFF 3.813rem)"),
                width: open ? '17.5rem' : '3.75rem',
                flexShrink: 0,
                transition: 'width 0.3s ease-in-out'
            }
        }, variant: "permanent", children: [_jsx(Backdrop, { isLoading: isLoading }), _jsx(ModalView, { isOpen: isTenantSelectorOpen, maxWidth: '88.875rem', maxHeight: '61.375rem', onClose: function () { return setIsTenantSelectorOpen(false); }, children: _jsx(TenantSelector, { onClick: function (id) { return switchToTenant(id); } }) }), _jsxs(List, { sx: {
                    marginTop: '2rem',
                    flex: 1
                }, children: [_jsx(AppMenuItem, { onClick: function () {
                            setIsTenantSelectorOpen(true);
                            setOpen(false);
                        }, primaryText: (_a = profile === null || profile === void 0 ? void 0 : profile.tenant.name) !== null && _a !== void 0 ? _a : 'loading', icon: _jsx(StoreOutlinedIcon, {}) }), _jsx(AppMenuItem, { onClick: function () {
                            return navigator(isPricerTenant() ? '/admin/users' : '/users');
                        }, primaryText: "Users", icon: _jsx(UserIcon, {}) }), !isPricerTenant() && (_jsxs(_Fragment, { children: [_jsx(AppMenuItem, { onClick: function () { return navigator('/tags'); }, primaryText: "Tags", icon: _jsx(TagSharp, {}) }), _jsx(AppMenuItem, { onClick: function () { return navigator('/access-keys'); }, primaryText: "Access Keys", icon: _jsx(ApiIcon, {}) })] })), isPricerTenant() && (_jsx(AppMenuItem, { onClick: function () { return navigator('/admin/connections'); }, primaryText: "Connections", icon: _jsx(CableIcon, {}) }))] }), _jsxs(List, { disablePadding: true, sx: {
                    pb: '1.5rem',
                    background: "linear-gradient(to right, ".concat(isAdminSide ? '#BF9F9F' : '#DDE9DB', " 3.75rem, #FFFFFF 3.813rem)")
                }, children: [_jsx(AppMenuItem, { href: "".concat(getConfig().adminConsole, "/profile"), buttonSx: { paddingLeft: '0.563rem' }, primaryText: "".concat((_b = profile === null || profile === void 0 ? void 0 : profile.givenName) !== null && _b !== void 0 ? _b : 'loading...', " ").concat((_c = profile === null || profile === void 0 ? void 0 : profile.familyName) !== null && _c !== void 0 ? _c : ''), secondaryText: (_d = profile === null || profile === void 0 ? void 0 : profile.email) !== null && _d !== void 0 ? _d : 'loading...', icon: _jsx(AvatarItem, { sx: { minWidth: 0 }, givenName: profile === null || profile === void 0 ? void 0 : profile.givenName, familyName: profile === null || profile === void 0 ? void 0 : profile.familyName }) }), _jsx(AppMenuItem, { onClick: function () {
                            return logout({ logoutParams: { returnTo: window.location.origin } });
                        }, primaryText: 'Sign Out', icon: _jsx(LogoutIcon, {}) })] })] }));
};
