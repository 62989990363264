var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getConfig } from '../common/config';
export function getApiStatusCode(error) {
    if (!error) {
        return 0;
    }
    var fetchError = error;
    if (fetchError.status === undefined) {
        return -1;
    }
    if (typeof fetchError.status === 'number') {
        return fetchError.status;
    }
    if (fetchError.status === 'PARSING_ERROR') {
        return fetchError.originalStatus;
    }
    return -1;
}
export var responseHandler = function (response, resource) {
    if (response.status === 401 || response.status === 403) {
        return Promise.resolve("Access Denied: ".concat(resource));
    }
    else if (response.status === 404) {
        return Promise.resolve("Resource Not Found: ".concat(resource));
    }
    else if (response.status === 204) {
        return Promise.resolve('No Content');
    }
    return Promise.resolve(response.json());
};
export var transformPaginatedResponse = function (apiResponse, meta) {
    var _a, _b;
    return {
        totalElements: Number(((_a = meta.response) === null || _a === void 0 ? void 0 : _a.headers.get('X-Total-Elements')) || 0),
        totalPages: Number((_b = meta.response) === null || _b === void 0 ? void 0 : _b.headers.get('X-Total-Pages')) || 0,
        data: apiResponse
    };
};
export function isFetchBaseQueryError(error) {
    return typeof error === 'object' && error != null && 'status' in error;
}
export var baseApi = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: getConfig().apiBase,
        prepareHeaders: function (headers, _a) {
            var getState = _a.getState;
            var token = getState().auth.token;
            if (token) {
                headers.set('authorization', "Bearer ".concat(token));
            }
            headers.set('Content-Type', 'application/json');
            return headers;
        }
    }),
    tagTypes: [
        'Connections',
        'Connection',
        'Tenants',
        'Users',
        'User',
        'AccessKeys',
        'AccessKey',
        'Overview'
    ],
    endpoints: function (builder) { return ({
        getConnections: builder.query({
            query: function () { return ({
                url: 'api/v1/connections',
                responseHandler: function (response) { return responseHandler(response, 'Connections'); }
            }); },
            providesTags: ['Connections']
        }),
        getConnection: builder.query({
            query: function (id) { return "api/v1/connections/".concat(id); },
            providesTags: function (_result, _error, id) { return [{ type: 'Connection', id: id }]; }
        }),
        saveConnection: builder.mutation({
            query: function (connection) { return ({
                url: "api/v1/connections/".concat(connection.id),
                method: 'PUT',
                body: __assign(__assign({}, connection), { id: undefined, tenantId: undefined }),
                responseHandler: function (response) {
                    return responseHandler(response, 'Update Connection');
                }
            }); },
            invalidatesTags: function (_result, _error, _a) {
                var id = _a.id;
                return [
                    { type: 'Connection', id: id },
                    { type: 'Connections' }
                ];
            }
        }),
        createConnection: builder.mutation({
            query: function (connection) { return ({
                url: "api/v1/connections",
                method: 'POST',
                body: connection,
                responseHandler: function (response) {
                    return responseHandler(response, 'Create Connection');
                }
            }); }
        }),
        getTenants: builder.query({
            query: function () { return ({
                url: 'api/v1/tenants',
                responseHandler: function (response) { return responseHandler(response, 'Tenants'); }
            }); },
            providesTags: ['Tenants']
        }),
        getPagedUsers: builder.query({
            query: function (_a) {
                var _b;
                var _c = _a.page, page = _c === void 0 ? 0 : _c, _d = _a.size, size = _d === void 0 ? 10 : _d, rest = __rest(_a, ["page", "size"]);
                return ({
                    url: "api/v1/users",
                    params: __assign(__assign({ page: page, size: size }, rest), { tag: (_b = rest.tag) === null || _b === void 0 ? void 0 : _b.tag }),
                    responseHandler: function (response) { return responseHandler(response, 'Users'); }
                });
            },
            providesTags: function (result) {
                return result
                    ? __spreadArray(__spreadArray([], result.data.map(function (_a) {
                        var id = _a.id;
                        return ({ type: 'Users', id: id });
                    }), true), [
                        { type: 'Users', id: 'PARTIAL-LIST' }
                    ], false) : [{ type: 'Users', id: 'PARTIAL-LIST' }];
            },
            transformResponse: (transformPaginatedResponse)
        }),
        getUser: builder.query({
            query: function (id) { return ({
                url: "api/v1/users/".concat(encodeURIComponent(id)),
                responseHandler: function (response) { return responseHandler(response, 'User'); }
            }); },
            providesTags: function (_result, _error, id) { return [{ type: 'User', id: id }]; },
            transformResponse: function (user) {
                return user.tags ? user : __assign(__assign({}, user), { tags: [] });
            }
        }),
        saveUser: builder.mutation({
            query: function (user) { return ({
                url: "api/v1/users/".concat(encodeURIComponent(user.id)),
                method: 'PUT',
                body: __assign(__assign({}, user), { id: undefined, email: undefined, username: undefined }),
                responseHandler: function (response) { return responseHandler(response, 'Update User'); }
            }); },
            onQueryStarted: function (_user_1, _a) {
                return __awaiter(this, arguments, void 0, function (_user, _b) {
                    var data, queries, id, _i, _c, _d, key, value;
                    var _e;
                    var dispatch = _b.dispatch, queryFulfilled = _b.queryFulfilled, getState = _b.getState;
                    return __generator(this, function (_f) {
                        switch (_f.label) {
                            case 0: return [4 /*yield*/, queryFulfilled];
                            case 1:
                                data = (_f.sent()).data;
                                queries = getState().api.queries;
                                id = data.id;
                                for (_i = 0, _c = Object.entries(queries); _i < _c.length; _i++) {
                                    _d = _c[_i], key = _d[0], value = _d[1];
                                    if (key.startsWith('getPagedUsers')) {
                                        dispatch(baseApi.util.updateQueryData('getPagedUsers', (_e = value === null || value === void 0 ? void 0 : value.originalArgs) !== null && _e !== void 0 ? _e : undefined, function (draft) {
                                            var user = draft.data.find(function (user) { return user.id === id; });
                                            if (user) {
                                                user.tags = data.tags;
                                                user.role = data.role;
                                                user.email = data.email;
                                                user.givenName = data.givenName;
                                                user.familyName = data.familyName;
                                                user.emailVerified = data.emailVerified;
                                                user.isClassicUser = data.isClassicUser;
                                            }
                                        }));
                                    }
                                }
                                return [2 /*return*/];
                        }
                    });
                });
            },
            invalidatesTags: function (_result, _error, _a) {
                var id = _a.id;
                return [{ type: 'User', id: id }];
            }
        }),
        inviteUser: builder.mutation({
            query: function (invitee) { return ({
                url: "api/v1/users",
                method: 'POST',
                body: {
                    email: invitee.email,
                    givenName: invitee.givenName,
                    familyName: invitee.familyName,
                    phoneNumber: invitee.phoneNumber,
                    role: invitee.role,
                    tags: invitee.tags
                },
                responseHandler: function (response) { return responseHandler(response, 'Create User'); }
            }); },
            onQueryStarted: function (_user_1, _a) {
                return __awaiter(this, arguments, void 0, function (_user, _b) {
                    var data, queries, _i, _c, _d, key, value;
                    var _e;
                    var dispatch = _b.dispatch, queryFulfilled = _b.queryFulfilled, getState = _b.getState;
                    return __generator(this, function (_f) {
                        switch (_f.label) {
                            case 0: return [4 /*yield*/, queryFulfilled];
                            case 1:
                                data = (_f.sent()).data;
                                queries = getState().api.queries;
                                for (_i = 0, _c = Object.entries(queries); _i < _c.length; _i++) {
                                    _d = _c[_i], key = _d[0], value = _d[1];
                                    if (key.startsWith('getPagedUsers')) {
                                        dispatch(baseApi.util.updateQueryData('getPagedUsers', (_e = value === null || value === void 0 ? void 0 : value.originalArgs) !== null && _e !== void 0 ? _e : undefined, function (draft) {
                                            draft.data.unshift(data);
                                            draft.totalElements += 1;
                                        }));
                                    }
                                }
                                dispatch(baseApi.util.updateQueryData('getUsersOverview', undefined, function (draft) {
                                    draft.unverifiedUsers += 1;
                                }));
                                return [2 /*return*/];
                        }
                    });
                });
            }
        }),
        deleteUser: builder.mutation({
            query: function (user) { return ({
                url: "api/v1/users/".concat(encodeURIComponent(user.id)),
                method: 'DELETE',
                responseHandler: function (response) { return responseHandler(response, 'Delete User'); }
            }); },
            onQueryStarted: function (user_1, _a) {
                return __awaiter(this, arguments, void 0, function (user, _b) {
                    var queries, _i, _c, _d, key, value;
                    var _e;
                    var dispatch = _b.dispatch, queryFulfilled = _b.queryFulfilled, getState = _b.getState;
                    return __generator(this, function (_f) {
                        switch (_f.label) {
                            case 0: return [4 /*yield*/, queryFulfilled];
                            case 1:
                                _f.sent();
                                queries = getState().api.queries;
                                for (_i = 0, _c = Object.entries(queries); _i < _c.length; _i++) {
                                    _d = _c[_i], key = _d[0], value = _d[1];
                                    if (key.startsWith('getPagedUsers')) {
                                        dispatch(baseApi.util.updateQueryData('getPagedUsers', (_e = value === null || value === void 0 ? void 0 : value.originalArgs) !== null && _e !== void 0 ? _e : undefined, function (draft) {
                                            draft.data = draft.data.filter(function (u) { return u.id !== user.id; });
                                            draft.totalElements -= 1;
                                        }));
                                    }
                                }
                                dispatch(baseApi.util.updateQueryData('getUsersOverview', undefined, function (draft) {
                                    draft.classicUsers -= user.isClassicUser ? 1 : 0;
                                    draft.unverifiedUsers -=
                                        !user.isClassicUser && !user.emailVerified ? 1 : 0;
                                }));
                                return [2 /*return*/];
                        }
                    });
                });
            }
        }),
        migrateClassicUser: builder.mutation({
            query: function (_a) {
                var id = _a.id, invitee = _a.invitee;
                return ({
                    url: "api/v1/users/".concat(encodeURIComponent(id), "/migrate"),
                    method: 'POST',
                    body: invitee,
                    responseHandler: function (response) { return responseHandler(response, 'Update User'); }
                });
            },
            onQueryStarted: function (_user_1, _a) {
                return __awaiter(this, arguments, void 0, function (_user, _b) {
                    var data, queries, id, _i, _c, _d, key, value;
                    var _e;
                    var dispatch = _b.dispatch, queryFulfilled = _b.queryFulfilled, getState = _b.getState;
                    return __generator(this, function (_f) {
                        switch (_f.label) {
                            case 0: return [4 /*yield*/, queryFulfilled];
                            case 1:
                                data = (_f.sent()).data;
                                queries = getState().api.queries;
                                id = data.id;
                                for (_i = 0, _c = Object.entries(queries); _i < _c.length; _i++) {
                                    _d = _c[_i], key = _d[0], value = _d[1];
                                    if (key.startsWith('getPagedUsers')) {
                                        dispatch(baseApi.util.updateQueryData('getPagedUsers', (_e = value === null || value === void 0 ? void 0 : value.originalArgs) !== null && _e !== void 0 ? _e : undefined, function (draft) {
                                            var user = draft.data.find(function (user) { return user.id === id; });
                                            if (user) {
                                                user.tags = data.tags;
                                                user.role = data.role;
                                                user.email = data.email;
                                                user.givenName = data.givenName;
                                                user.familyName = data.familyName;
                                                user.emailVerified = data.emailVerified;
                                                user.isClassicUser = data.isClassicUser;
                                            }
                                        }));
                                    }
                                }
                                dispatch(baseApi.util.updateQueryData('getUsersOverview', undefined, function (draft) {
                                    draft.classicUsers -= 1;
                                }));
                                return [2 /*return*/];
                        }
                    });
                });
            },
            invalidatesTags: ['User']
        }),
        getPackages: builder.query({
            query: function () { return ({
                url: 'api/v1/packages',
                responseHandler: function (response) { return responseHandler(response, 'Packages'); }
            }); }
        }),
        getRoles: builder.query({
            query: function () { return ({
                url: 'api/v1/roles',
                responseHandler: function (response) { return responseHandler(response, 'User roles'); }
            }); }
        }),
        triggerEmail: builder.query({
            query: function (id) { return ({
                url: "/api/v1/users/".concat(encodeURIComponent(id), "/trigger/email"),
                method: 'POST'
            }); }
        }),
        getAccessKeys: builder.query({
            query: function () { return ({
                url: 'api/v1/access-keys',
                responseHandler: function (response) { return responseHandler(response, 'AccessKeys'); }
            }); },
            providesTags: ['AccessKeys']
        }),
        getAccessKey: builder.query({
            query: function (id) { return ({
                url: "api/v1/access-keys/".concat(id),
                responseHandler: function (response) { return responseHandler(response, 'AccessKey'); }
            }); },
            providesTags: ['AccessKey']
        }),
        createAccessKey: builder.mutation({
            query: function (key) { return ({
                url: "api/v1/access-keys",
                method: 'POST',
                body: __assign({}, key),
                responseHandler: function (response) {
                    return responseHandler(response, 'Create AccessKey');
                }
            }); },
            invalidatesTags: ['AccessKeys']
        }),
        deleteAccessKey: builder.mutation({
            query: function (id) { return ({
                url: "api/v1/access-keys/".concat(id),
                method: 'DELETE',
                responseHandler: function (response) {
                    return responseHandler(response, 'Delete AccessKey');
                }
            }); },
            invalidatesTags: ['AccessKeys']
        }),
        updateAccessKey: builder.mutation({
            query: function (key) { return ({
                url: "api/v1/access-keys/".concat(key.id),
                method: 'PUT',
                body: {
                    name: key.name,
                    validFor: 0,
                    permissions: key.permissions
                },
                responseHandler: function (response) {
                    return responseHandler(response, 'Update AccessKey');
                }
            }); },
            invalidatesTags: ['AccessKeys', 'AccessKey']
        }),
        regenerateAccessKey: builder.mutation({
            query: function (_a) {
                var id = _a.id, extendedTime = _a.extendedTime;
                return ({
                    url: "api/v1/access-keys/".concat(id, "/regenerate"),
                    method: 'PATCH',
                    body: {
                        extendedTime: extendedTime
                    },
                    responseHandler: function (response) {
                        return responseHandler(response, 'Update AccessKey');
                    }
                });
            },
            transformResponse: function (response) {
                return response.accessKey;
            },
            invalidatesTags: ['AccessKeys', 'AccessKey']
        }),
        getM2mToken: builder.query({
            query: function (accessKey) { return ({
                url: 'api/v1/oauth/token',
                method: 'GET',
                headers: {
                    'access-key': accessKey
                },
                responseHandler: function (response) { return responseHandler(response, 'M2M Token'); }
            }); },
            transformResponse: function (response, meta) {
                var _a, _b, _c;
                var rateLimit = {
                    rateLimit: Number(((_a = meta.response) === null || _a === void 0 ? void 0 : _a.headers.get('X-Ratelimit-limit')) || 0),
                    rateLimitRemaining: Number(((_b = meta.response) === null || _b === void 0 ? void 0 : _b.headers.get('X-Ratelimit-remaining')) || 0),
                    rateLimitReset: Number(((_c = meta.response) === null || _c === void 0 ? void 0 : _c.headers.get('X-Ratelimit-reset')) || 0)
                };
                response.rateLimit = rateLimit;
                return response;
            }
        }),
        getAccessKeyPermissions: builder.query({
            query: function () { return ({
                url: 'api/v1/access-key-permissions',
                method: 'GET',
                responseHandler: function (response) {
                    return responseHandler(response, 'AccessKeyPermissions');
                }
            }); }
        }),
        getUsersOverview: builder.query({
            query: function () { return ({
                url: 'api/v1/users/overview',
                method: 'GET',
                responseHandler: function (response) { return responseHandler(response, 'Overview'); }
            }); },
            providesTags: ['Overview']
        })
    }); }
});
export var useGetPackagesQuery = baseApi.useGetPackagesQuery, useGetConnectionsQuery = baseApi.useGetConnectionsQuery, useGetConnectionQuery = baseApi.useGetConnectionQuery, useSaveConnectionMutation = baseApi.useSaveConnectionMutation, useCreateConnectionMutation = baseApi.useCreateConnectionMutation, useGetTenantsQuery = baseApi.useGetTenantsQuery, useGetPagedUsersQuery = baseApi.useGetPagedUsersQuery, useLazyGetPagedUsersQuery = baseApi.useLazyGetPagedUsersQuery, useGetUserQuery = baseApi.useGetUserQuery, useLazyGetUserQuery = baseApi.useLazyGetUserQuery, useSaveUserMutation = baseApi.useSaveUserMutation, useDeleteUserMutation = baseApi.useDeleteUserMutation, useInviteUserMutation = baseApi.useInviteUserMutation, useMigrateClassicUserMutation = baseApi.useMigrateClassicUserMutation, useGetRolesQuery = baseApi.useGetRolesQuery, useLazyTriggerEmailQuery = baseApi.useLazyTriggerEmailQuery, useGetAccessKeysQuery = baseApi.useGetAccessKeysQuery, useGetAccessKeyQuery = baseApi.useGetAccessKeyQuery, useCreateAccessKeyMutation = baseApi.useCreateAccessKeyMutation, useDeleteAccessKeyMutation = baseApi.useDeleteAccessKeyMutation, useUpdateAccessKeyMutation = baseApi.useUpdateAccessKeyMutation, useRegenerateAccessKeyMutation = baseApi.useRegenerateAccessKeyMutation, useLazyGetM2mTokenQuery = baseApi.useLazyGetM2mTokenQuery, useGetAccessKeyPermissionsQuery = baseApi.useGetAccessKeyPermissionsQuery, useGetUsersOverviewQuery = baseApi.useGetUsersOverviewQuery;
